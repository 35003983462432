import SectionTitle from "./sectionTitle";
import FadeInElement from "./fadeInElement";
import {useApp} from "./appContext";

const images = [
    {
        img: "midnight-logo.png"
    },
    {
        img: "vodafone-logo.png"
    },
    {
        img: "lufthansa-logo.png"
    },
    {
        img: "msm-logo.png"
    }
]

function CompaniesView() {
    const {getText} = useApp();

    return (
        <div>
            <SectionTitle name={'section.clients'} />
            <div className={'companies-layout'}>
                {images.map((item, index) => {
                    return (
                        <FadeInElement key={index}>
                            <img alt={"img"} src={"./../images/" + item.img} className={'company-item'}/>
                        </FadeInElement>
                    )
                })}
            </div>
        </div>
    )
}

export default CompaniesView;