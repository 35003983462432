import {useState} from "react";
import {useApp} from "./appContext";

const languages = [
    "en", "de"
]

function LanguageSelection() {
    const {language, setLanguage} = useApp();
    const [open, setOpen] = useState(false);

    function chooseLanguage(language) {
        setLanguage(language);
        setOpen(false);
    }

    return (
        <div className={'language-selection'}>
            <div className={'border language-selection-item language-selection-main-item'} onClick={() => setOpen(!open)}>
                <img alt={'img'} className={'language-selection-item'} src={`/images/${language}.png`}/>
            </div>
            {open && <div className={'fade-in-element-fast border'} style={{display: 'flex', flexDirection: 'row-reverse', alignItems: 'center', gap: 5, padding: 3, backgroundColor: 'var(--color-box)', borderRadius: 50}}>
                {languages.map((l, i) => {
                    return <div key={i} className={'language-selection-item'} onClick={() => chooseLanguage(l)}>
                        <img alt={'img'} className={'language-selection-item'} src={`/images/${l}.png`}/>
                    </div>
                })}
            </div>}
        </div>
    )
}

export default LanguageSelection;