import {useEffect, useState} from "react";
import ReactTypingEffect from 'react-typing-effect';
import ConnectButton from "./connectButton";
import {useApp} from "./appContext";

function Header() {
    const {getText} = useApp();

    const skills = getText('header.skills');

    return (
        <div className="header">
            <div className="header-layout">
                <div className={'header-child'}>
                    <img alt={'img'} className={'profile-img'} src={'./images/profile-img.png'}/>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'column', gap: 5}}>
                        <p className={'header-title text-bold text-typed'}
                                           style={{color: 'var(--color-tint)'}}>
                            {getText('header.title')}
                        </p>
                        <p className="header-tagline">{getText('header.tagline')}</p>
                    </div>
                </div>
                <div className={'header-details'}>
                    {Array.isArray(skills) && skills.map((skill, index) => {
                        return <p key={index} className={'header-details-value'}>{skill}</p>
                    })}
                </div>
                <div className={'header-cta'}>
                    <ConnectButton/>
                </div>
            </div>
            <Arrow/>
        </div>
    )
}

function Arrow() {
    const [shown, setShown] = useState(true);
    const [timeout, setRunningTimeout] = useState();

    useEffect(() => {
        window.addEventListener('scroll', onScroll);
        return (() => {
            window.removeEventListener('scroll', onScroll);
        })
    })

    function onScroll() {
        const scrollDiv = document.getElementById("arrow-scroll");
        const hideIt = document.body.scrollTop > 50 || document.documentElement.scrollTop > 50;

        if (!shown && !hideIt) {
            scrollDiv.style.display = "grid";
            clearTimeout(timeout);
            const newTimeout = setTimeout(function () {
                scrollDiv.style.opacity = 1;
            }, 10);
            setRunningTimeout(newTimeout);
            setShown(true);
        } else if (shown && hideIt) {
            scrollDiv.style.opacity = 0;
            clearTimeout(timeout);
            const newTimeout = setTimeout(function() { scrollDiv.style.display = "none"; }, 500);
            setRunningTimeout(newTimeout);
            setShown(false);
        }
    }

    return (
        <span id={'arrow-scroll'} className={'fa-solid fa-arrow-down bounce'} style={{color: 'var(--color-main)', position: 'fixed', bottom: '6%', left: 'calc(50% - 11.25px)', fontSize: 'var(--font-size-title)'}}/>
    )
}

export default Header;
