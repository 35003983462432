import React, { useEffect, useState, useRef } from 'react';

const FadeInElement = ({className, children}) => {
    const [isVisible, setIsVisible] = useState(false);
    const divRef = useRef(null);

    const checkVisibility = () => {
        if (divRef.current) {
            const rect = divRef.current.getBoundingClientRect();
            const visible = rect.top >= -rect.height && rect.bottom <= window.innerHeight + rect.height;
            if (visible) {
                setIsVisible(true);
            }
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', checkVisibility);
        checkVisibility();
        return () => window.removeEventListener('scroll', checkVisibility);
    }, []);

    return (
        <div ref={divRef} className={`${className} ${isVisible ? 'fade-in-element' : 'hidden'}`}>
            {children}
        </div>
    );
};

export default FadeInElement;