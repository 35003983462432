import SectionTitle from "./sectionTitle";
import FadeInElement from "./fadeInElement";
import {useApp} from "./appContext";

const skills = [
    "ar", "mobile", "server", "games"
]

const basicSkills = [
    "web3", "websites", "tools", "management"
]

function SkillsView() {
    return (
        <>
            <div>
                <SectionTitle name={'section.specialities'}/>
                <div className={'skills-view'}>
                    {skills.map((skill, index) => {
                        return <SkillItem key={index} skill={skill} basic={false}/>
                    })}
                </div>
            </div>
            <div>
                <SectionTitle name={'section.skills'}/>
                <div className={'skills-view'}>
                    {basicSkills.map((skill, index) => {
                        return <SkillItem key={index} skill={skill} basic={true}/>
                    })}
                </div>
            </div>
        </>
    )
}

function SkillItem({skill, basic}) {
    const {getText} = useApp();

    return (
        <FadeInElement className={`border box round skill-item ${basic ? 'skill-item-basic' : ''}`}>
            {!basic && <div style={{display: 'flex', justifyContent: 'center'}}>
                <img alt={'img'} className={'skill-icon'} src={`/images/skill_${skill}.png`}/>
            </div>}
            <div className={'skill-texts'}>
                <p style={{
                    width: '100%',
                    fontSize: 'var(--font-size-title)',
                    fontFamily: 'Roboto'
                }}>{getText(`skill.${skill}.title`)}</p>
                {!basic && <p style={{
                    width: '100%',
                    fontFamily: 'Roboto-Regular',
                    color: 'var(--color-secondary)'
                }}>{getText(`skill.${skill}.text`)}</p>}
            </div>
        </FadeInElement>
    )
}

export default SkillsView;