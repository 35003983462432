import { Link } from "react-router-dom";
import Footer from './footer';

function Impressum(args) {

    return (
        <div>
            <div>
                <div className="legal-container">
                    <Link to="/">
                        <p className="legal-back">Home</p>
                    </Link>
                    <p className="legal-title">Impressum</p>
                    <h2 className="legal-text-title">Representative</h2>
                    <p className="legal-text">Andy Grüning</p>
                    <br/>
                    <h2 className="legal-text-title">Address</h2>
                    <p className="legal-text">Mühlenstraße 20</p>
                    <p className="legal-text">10243 Berlin Germany</p>
                    <br/>
                    <h2 className="legal-text-title">Contact</h2>
                    <p className="legal-text">E-Mail: andy@andygruening.com</p>
                    <p className="legal-text">Telefon: +49 151 50218411</p>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Impressum;
