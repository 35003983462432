import {useApp} from "./appContext";

function ConnectButton() {
    const {getText} = useApp();

    function callPhone() {
        window.open(`tel:+4915150218411`, '_self');
        window.fathom.trackEvent('call-phone');
    }

    return (
        <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
            <button className={'header-connect-button'} onClick={callPhone}>{getText('button.call')}</button>
        </div>
    )
}

export default ConnectButton;