import {useEffect, useState} from "react";
import {useApp} from "./appContext";

const email = "andygruening@gmail.com"
const subject = "Please get in touch with me.";
const body = "Hi there!";

const calendly = "https://calendly.com/andygruening";

function Contact() {
    const {getText} = useApp();
    const [shown, setShown] = useState(false);
    const [timeout, setRunningTimeout] = useState();

    useEffect(() => {
        window.addEventListener('scroll', onScroll);
        return (() => {
            window.removeEventListener('scroll', onScroll);
        })
    })

    function onScroll() {
        const scrollDiv = document.getElementById("contact-scroll");
        const showIt = document.body.scrollTop > 50 || document.documentElement.scrollTop > 50;

        if (!shown && showIt) {
            scrollDiv.style.display = "flex";
            clearTimeout(timeout);
            const newTimeout =  setTimeout(function() { scrollDiv.style.opacity = 1; }, 10);
            setRunningTimeout(newTimeout);
            setShown(true);
        } else if (shown && !showIt) {
            scrollDiv.style.opacity = 0;
            clearTimeout(timeout);
            const newTimeout = setTimeout(function() { scrollDiv.style.display = "none"; }, 500);
            setRunningTimeout(newTimeout);
            setShown(false);
        }
    }

    function callPhone() {
        window.open(`tel:+4915150218411`, '_self');
        window.fathom.trackEvent('call-phone');
    }

    return (
        <div id={'contact-scroll'} className={'contact-view'}>
            <p className={'contact-text'}>{getText('get.in.touch')}</p>
            <div className={'fa-solid fa-arrow-right'} />
            <ContactItem icon={'fa-solid fa-phone'} name={'+49 151 50218411'} onClick={callPhone} />
        </div>
    )
}

function ContactItem({icon, name, onClick}) {
    const {getText} = useApp();

    return (
        <div className={'contact-item'} onClick={onClick}>
            <div className={icon} />
            <p className={'contact-text'}>{getText(name)}</p>
        </div>
    )
}

export default Contact;
