import './index.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import ProjectWindow from "./projectWindow";
import {AppProvider} from "./appContext";
import Impressum from "./impressum";
import Privacy from "./privacy";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <AppProvider>
          <BrowserRouter>
              <Routes>
                  <Route exact path="/" element={<App />} />
                  <Route exact path="/projects" element={<ProjectWindow />} />
                  <Route exact path="/impressum" element={<Impressum />} />
                  <Route exact path="/privacy" element={<Privacy />} />
                  <Route exact path="*" element={<App />} />
              </Routes>
          </BrowserRouter>
      </AppProvider>
  </React.StrictMode>
);
