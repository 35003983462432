import {createContext, useContext, useEffect, useState} from 'react';
import { projects } from './items';

const AppContext = createContext({});

const localization = require('./localization.json');

export function AppProvider(props) {
    const [gameId, setGameId] = useState(projects[0].id);
    const [language, setLanguageInternal] = useState('');

    useEffect(() => {
        let language = localStorage.getItem('website-language');
        if (language === null) {
            const userLanguage = navigator.language;
            language = userLanguage in localization ? userLanguage : 'en';
        }

        setLanguageInternal(language);
    }, []);

    function setLanguage(language) {
        localStorage.setItem('website-language', language);
        setLanguageInternal(language);
    }

    function getText(key) {
        if (!(language in localization) || !(key in localization[language])) {
            return key;
        }

        return localization[language][key];
    }

    return (
        <AppContext.Provider
            value={{
                gameId,
                setGameId,
                language,
                setLanguage,
                getText,
            }}
        >
            {props.children}
        </AppContext.Provider>
    );
}

export const useApp = () => useContext(AppContext);