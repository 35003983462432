import ConnectButton from "./connectButton";
import {useNavigate} from "react-router-dom";

const email = "andy@andygruening.com"
const subject = "Please get in touch with me.";
const body = "Hi there!";

function EndView() {
    const navigate = useNavigate();

    return (
        <div className={'end-view'}>
            <ConnectButton />
            <div style={{display: 'flex', justifyContent: 'center', width: '100%', gap: '10px'}}>
                <LinkButton icon={'fa-brands fa-linkedin'} link={'https://www.linkedin.com/in/andygruening/'}/>
                <LinkButton icon={'fa-solid fa-envelope'} link={`mailto:${email}?subject=${subject}&body=${body}`}/>
                <LinkButton icon={'fa-solid fa-calendar'} link={'https://calendly.com/andygruening'}/>
                <LinkButton icon={'fa-brands fa-github'} link={'https://github.com/andygruening'}/>
            </div>
            <div style={{display: 'flex', justifyContent: 'center', width: '100%', gap: '10px'}}>
                <a style={{fontFamily: 'Roboto-Regular'}} onClick={() => navigate('/impressum')}>Impressum</a>
                <a style={{fontFamily: 'Roboto-Regular'}} onClick={() => navigate('/privacy')}>Privacy</a>
            </div>
        </div>
    )
}

function LinkButton({icon, link}) {
    function openLink() {
        window.open(link);
    }

    return (
        <div onClick={openLink}>
            <div className={`border link-icon-button ${icon}`}/>
        </div>
    )
}

export default EndView;