import SectionTitle from "./sectionTitle";
import FadeInElement from "./fadeInElement";
import {useApp} from "./appContext";
import {useNavigate} from "react-router-dom";

function ListView({items}) {
    const { setGameId, getText } = useApp();
    const navigate = useNavigate();

    function openProject(project) {
        setGameId(project.id)
        navigate('/projects');
    }

    return (
        <div>
            <SectionTitle name={'section.work'} />
            <div className="projects-view">
                { items.map((project, index) => {
                    return (
                        <FadeInElement key={index} className="border box round project-item" >
                            <div className={'project-img'} style={{backgroundImage: `url(.${project.icon})`}}/>
                            <p className={'project-title'}>{getText(project.title)}</p>
                            <button className={' view-project-button'} onClick={() => openProject(project)}>{getText('take.a.look')}</button>
                        </FadeInElement>
                    )
                })}
            </div>
        </div>
    )
}

export default ListView;
