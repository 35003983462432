export const projects = [
    {
        id: "casual.climb",
        title: "project.casual.climb.title",
        name: "project.casual.climb.name",
        description: "project.casual.climb.text",
        icon: "/images/icon_casualclimb.png",
        skills: [],
        links: [
            {
                name: "button.ios",
                url: "https://apps.apple.com/de/app/casual-climb-online-rpg/id6451356132"
            },
            {
                name: "button.android",
                url: "https://play.google.com/store/apps/details?id=com.gruening.games.casual.climb"
            }
        ],
        images: [
            "/images/casual_climb/cc1.png",
            "/images/casual_climb/cc2.png",
            "/images/casual_climb/cc3.png",
            "/images/casual_climb/cc4.png",
            "/images/casual_climb/cc5.png",
            "/images/casual_climb/cc6.png",
            "/images/casual_climb/cc7.png",
        ],
        responsibilities: [
            "project.casual.climb.resp.1",
            "project.casual.climb.resp.2",
            "project.casual.climb.resp.3",
            "project.casual.climb.resp.4",
            "project.casual.climb.resp.5",
            "project.casual.climb.resp.6",
        ],
        techStack: [
            "project.casual.climb.tech.1",
            "project.casual.climb.tech.2",
            "project.casual.climb.tech.3",
            "project.casual.climb.tech.4",
            "project.casual.climb.tech.5",
            "project.casual.climb.tech.6",
        ]
    },
    {
        id: "shunks",
        title: "project.shunks.title",
        name: "project.shunks.name",
        description: "project.shunks.text",
        icon: "/images/icon_shunks.png",
        skills: [],
        links: [
            {
                name: "button.website",
                url: "https://www.shunks.xyz/"
            }
        ],
        images: [
            "/images/shunks/ss_0.png",
            "/images/shunks/ss_2.png",
            "/images/shunks/ss_3.png",
            "/images/shunks/ss_1.png",
        ],
        responsibilities: [
            "project.shunks.resp.1",
            "project.shunks.resp.2",
            "project.shunks.resp.3",
            "project.shunks.resp.4",
            "project.shunks.resp.5",
            "project.shunks.resp.6",
        ],
        techStack: [
            "project.shunks.tech.1",
            "project.shunks.tech.2",
            "project.shunks.tech.3",
            "project.shunks.tech.4",
            "project.shunks.tech.5",
            "project.shunks.tech.6",
        ]
    },
    {
        id: "a-boys-journey",
        title: "project.abj.title",
        name: "project.abj.name",
        description: "project.abj.text",
        icon: "/images/icon_a_boys_journey.png",
        skills: [],
        links: [
            {
                name: "button.steam",
                url: "https://store.steampowered.com/app/1598880/A_Boys_Journey/"
            }
        ],
        images: [
            "/images/abj/ss_1.jpg",
            "/images/abj/ss_2.jpg",
            "/images/abj/ss_3.jpg",
            "/images/abj/ss_4.jpg",
        ],
        responsibilities: [
            "project.abj.resp.1",
            "project.abj.resp.2",
            "project.abj.resp.3",
            "project.abj.resp.4",
        ],
        techStack: [
            "project.abj.tech.1",
            "project.abj.tech.2",
            "project.abj.tech.3",
        ]
    },
    {
        id: "metarcube",
        title: "project.metarcube.title",
        name: "project.metarcube.name",
        description: "project.metarcube.text",
        icon: "/images/icon_metarcube.png",
        skills: [],
        link: "https://hastyclimb.page.link/Zi7X",
        links: [
            {
                name: "button.ios",
                url: "https://hastyclimb.page.link/Zi7X"
            },
            {
                name: "button.android",
                url: "https://www.metarcube.com/"
            }
        ],
        images: [
            "/images/metarcube/mc_1.png",
            "/images/metarcube/mc_2.png",
            "/images/metarcube/mc_3.png",
            "/images/metarcube/mc_4.png",
        ],
        responsibilities: [
            "project.metarcube.resp.1",
            "project.metarcube.resp.2",
            "project.metarcube.resp.3",
        ],
        techStack: [
            "project.metarcube.tech.1",
            "project.metarcube.tech.2",
            "project.metarcube.tech.3",
            "project.metarcube.tech.4",
        ]
    },
    {
        id: "tobi",
        title: "project.tobi.title",
        name: "project.tobi.name",
        description: "project.tobi.text",
        icon: "/images/icon_tobi.png",
        skills: [],
        links: [
            {
                name: "button.website",
                url: "https://www.vodafone.com/"
            }
        ],
        images: [
        ],
        responsibilities: [
            "project.tobi.resp.1",
            "project.tobi.resp.2",
            "project.tobi.resp.3",
            "project.tobi.resp.4",
        ],
        techStack: [
            "project.tobi.tech.1",
            "project.tobi.tech.2",
            "project.tobi.tech.3",
            "project.tobi.tech.4",
        ]
    },
    {
        id: "ally",
        title: "project.ally.title",
        name: "project.ally.name",
        description: "project.ally.text",
        icon: "/images/icon_ally.png",
        skills: [],
        links: [
            {
                name: "button.ios",
                url: "https://apps.apple.com/ca/app/ally-fun-social-charades-game/id1510719148"
            }
        ],
        images: [
            "/images/ally/ally_1.png",
            "/images/ally/ally_2.png",
            "/images/ally/ally_3.png",
            "/images/ally/ally_4.png",
        ],
        responsibilities: [
            "project.ally.resp.1",
            "project.ally.resp.2",
            "project.ally.resp.3",
        ],
        techStack: [
            "project.ally.tech.1",
        ]
    }
]