import {useEffect, useState} from "react";

function Overlay() {
    const [show, setShow] = useState(true);
    const [animation, setAnimation] = useState('');

    useEffect(() => {
        setScrolling(false);

        setTimeout(async () => {
            setScrolling(true);
            await close();
        }, 1000);
    }, [])

    async function close() {
        setAnimation('out');
        await new Promise(r => setTimeout(r, 290));
        setAnimation('');
        setShow(false);
    }

    function setScrolling(enable) {
        const body = document.body;
        if (enable) {
            body.classList.remove('no-scroll');
        } else {
            body.classList.add('no-scroll');
        }
    }

    if (show) {
        return (
            <div className={'overlay window ' + animation}>
                <p className={'header-title text-bold text-typed overlay-text'}>Andy Grüning</p>
            </div>
        )
    } else {
        return (
            <></>
        )
    }
}

export default Overlay;