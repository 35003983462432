import {useApp} from "./appContext";
import { projects } from './items';
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import LanguageSelection from "./languageSelection";

function ProjectWindow() {
    const { gameId, setGameId, getText } = useApp();
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    })

    function openProject(url) {
        window.open(url, "_blank").focus();
        window.fathom.trackEvent(gameId);
    }

    async function close() {
        navigate('/');
    }

    function getItem() {
        return projects.find(p=> p.id === gameId);
    }

    function previousProject() {
        const curIndex = projects.findIndex(p => p.id === gameId);
        const nextIndex = curIndex <= 0 ? projects.length - 1 : curIndex - 1;
        setGameId(projects[nextIndex].id);
    }

    function nextProject() {
        const curIndex = projects.findIndex(p => p.id === gameId);
        const nextIndex = curIndex >= projects.length - 1 ? 0 : curIndex + 1;
        setGameId(projects[nextIndex].id);
    }

    return (
        <div className={"project-window window"}>
            <div className={'project-window-box'}>
                <div className="project-window-title-parent">
                    <p className="project-window-title text-bold">{getText(getItem().name)}</p>
                    <div className={'project-links'}>
                        <button className={'project-button'} onClick={previousProject}><span className={'fa-solid fa-arrow-left'}/></button>
                        <button className={'project-button'} onClick={nextProject}><span className={'fa-solid fa-arrow-right'}/></button>
                        <button className={'project-button'} onClick={close}><span className={'fa-solid fa-close'}/></button>
                    </div>
                </div>
                <div>
                    <p className={'about-title'}>{getText('about')}</p>
                    <p className={'about-text'}>{getText(getItem().description)}</p>
                </div>
                <div className={'project-footer-box'}>
                    <div className={'project-links'}>
                        {getItem().links.map((item, index) => {
                            return (
                                <button key={index} className={'header-connect-button project-link-button'} onClick={() => openProject(item.url)}>{getText(item.name)}</button>
                            )
                        })}
                    </div>
                </div>
                <div style={{display: "grid", gridTemplateColumns: '1fr 1fr', gap: '20px'}}>
                    <List title={getText('section.responsibilities')} elements={getItem().responsibilities}/>
                    <List title={getText('section.tech.stack')} elements={getItem().techStack}/>
                </div>
                {getItem().images.length > 0 && <div className={'border box round'} style={{
                    backgroundColor: 'var(--color-box)',
                    marginBottom: '40px',
                    borderRadius: 'var(--border-radius)',
                }}>
                    <p className={'about-title'}>{getText('take.a.look')}</p>
                    <div style={{
                        padding: '20px 0',
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        gap: '20px',
                    }}>
                        {getItem().images.map((image, index) => {
                            return <img key={index} alt={'img'} src={image} className={'project-screenshot'}/>
                        })}
                    </div>
                </div>}
            </div>
            <LanguageSelection/>
        </div>
    )
}

function List({title, elements}) {
    return (
        <div>
            <p className={'about-title'}>{title}</p>
            <div style={{marginTop: '10px', display: 'flex', flexDirection: 'column', gap: '5px'}}>
                {elements.map((item, index) => {
                    return <ListText key={index} text={item}/>
                })}
            </div>
        </div>
    )
}

function ListText({text}) {
    const {getText} = useApp();

    return <p className={'about-text'} style={{display: 'flex', alignItems: 'center'}}><span className={'fa-solid fa-circle'} style={{fontSize: '10px', marginRight: '10px'}}/>{getText(text)}</p>
}

export default ProjectWindow;