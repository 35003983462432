import './App.css';
import './animations.css';
import './legal.css';
import './fa/styles/all.css';
import Footer from './footer';
import Header from './header';
import ListView from './listView';
import { projects } from './items';
import Contact from "./contact";
import CompaniesView from "./companiesView";
import Overlay from "./overlay";
import SkillsView from "./skillsView";
import EndView from "./endView";
import LanguageSelection from "./languageSelection";

function App() {
  return (
    <>
        <div className="container">
            <Header />
            <div className={'content-layout'}>
                <CompaniesView />
                <ListView items={projects} />
                <SkillsView />
                <EndView />
            </div>
            <Footer />
            <Contact />
        </div>
        <LanguageSelection />
        <Overlay />
    </>
  );
}

export default App;
