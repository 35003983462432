import { Link } from "react-router-dom";
import Footer from "./footer";
import {useEffect} from "react";

function Privacy(args) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <div>
            <div>
                <div className="legal-container">
                    <Link to="/">
                        <p className="legal-back">Home</p>
                    </Link>
                    <p className="legal-title">Privacy Policy</p>
                    <p className="legal-text">Effective Date: 04.07.2024</p><br/>
                    <p className="legal-text">At Gruening Games, we value your privacy and are committed to protecting your personal information. This Privacy Policy outlines our practices for collecting, using, and disclosing information on our website. By using our website, you agree to the collection and use of information in accordance with this policy.</p><br/>
                    <h2 className="legal-text-title">Information Collection and Use</h2><br/>
                    <p className="legal-text">We employ cookieless analytics to collect non-personally identifiable information about your interaction with our website. This information includes, but is not limited to:</p><br/>
                    <ul>
                        <li><p className="legal-text">IP address</p></li>
                        <li><p className="legal-text">Browser type and version</p></li>
                        <li><p className="legal-text">Operating system</p></li>
                        <li><p className="legal-text">Referring and exit pages</p></li>
                        <li><p className="legal-text">Date and time of visit</p></li>
                        <li><p className="legal-text">Duration of visit</p></li>
                        <li><p className="legal-text">Pages viewed</p></li>
                    </ul>
                    <br/>
                    <p className="legal-text">The collected data is used to analyze trends and improve the user experience on our website. We do not use cookies or similar tracking technologies.</p><br/>
                    <h2 className="legal-text-title">Information Sharing</h2><br/>
                    <p className="legal-text">We do not share, sell, rent, or trade your personal information with third parties. The non-personally identifiable information collected through our analytics may be shared with third-party service providers for the sole purpose of enhancing our website's performance and user experience.</p><br/>
                    <h2 className="legal-text-title">Data Security</h2><br/>
                    <p className="legal-text">We take reasonable precautions to protect your information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure. Therefore, while we strive to protect your information, we cannot guarantee its absolute security.</p><br/>
                    <h2 className="legal-text-title">External Links</h2><br/>
                    <p className="legal-text">Our website may contain links to external sites that are not operated by us. Please be aware that we have no control over the content and privacy practices of these sites. We encourage you to review the privacy policies of each site you visit.</p><br/>
                    <h2 className="legal-text-title">Changes to This Privacy Policy</h2><br/>
                    <p className="legal-text">We may update our Privacy Policy from time to time. Any changes will be posted on this page, and the "Effective Date" at the top of this Privacy Policy will be updated. We encourage you to review this Privacy Policy periodically for any changes.</p><br/>
                    <h2 className="legal-text-title">Contact Us</h2><br/>
                    <p className="legal-text">If you have any questions or concerns regarding this Privacy Policy or our privacy practices, please contact us at:</p><br/>
                    <p className="legal-text">{"Gruening Games UG (haftungsbeschränkt)"}</p>
                    <p className="legal-text">Mühlenstraße 20</p>
                    <p className="legal-text">10243 Berlin Germany</p>
                    <p className="legal-text">E-Mail: andy@andygruening.com</p>
                    <p className="legal-text text-margin">Phone: +49 151 50218411</p>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Privacy;
