import { Link } from "react-router-dom";

function Footer(args) {

    return (
        <div className="footer">
        </div>
    )
}

export default Footer;
