import {useApp} from "./appContext";

function SectionTitle({name}) {
    const {getText} = useApp();

    return (
        <div>
            <p className={'text-bold projects-title'}>{getText(name)}</p>
            <p className={'projects-tagline'}>{getText(`${name}.tagline`)}</p>
        </div>
    )
}

export default SectionTitle;